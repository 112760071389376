






























import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    RadioToggle,
  },
})
export default class OtherSettings extends Vue {
  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: 'その他設定', active: true },
  ]

  private useRecommend = true
  private useFeedback = true

  private attributeDatas: {
    id: number
    name: string
  }[] = []

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas() {
    await this.loadSeminarRecommendation()
    await this.loadBranchFeedback()
  }

  // レコメンド設定状況の取得
  private async loadSeminarRecommendation() {
    await Vue.prototype.$http.httpWithToken
      .get('/seminar_recommendations', { params: { branchId: this.branchId } })
      .then((res: any) => {
        this.useRecommend = res.data.isValid
      })
  }

  private async updateSeminarRecommendation() {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken
      .patch('/seminar_recommendations', { branchId: this.branchId, isValid: this.useRecommend })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
    await this.loadSeminarRecommendation()
    Vue.prototype.$loading.complete()
  }

  private async loadBranchFeedback() {
    await Vue.prototype.$http.httpWithToken
      .get('/branch_feedbacks', { params: { branchId: this.branchId } })
      .then((res: any) => {
        this.useFeedback = res.data.isFeedback
      })
  }

  private async updateBranchFeedback() {
    Vue.prototype.$loading.start()
    await Vue.prototype.$http.httpWithToken
      .patch('/branch_feedbacks', { branchId: this.branchId, isFeedback: this.useFeedback })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
    await this.loadBranchFeedback()
    Vue.prototype.$loading.complete()
  }
}
